import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Headline from "../components/headline"
import styles from "./index.module.css"

const QUERY = graphql`
{
  allMarkdownRemark(
    sort: { order: DESC, fields: [frontmatter___date] }
    limit: 5
  ) {
    edges {
      node {
        htmlAst
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          iso8601date: date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
          keywords
          path
        }
      }
    }
  }
}`

const SUMMARY_ELEMENTS = 4

const mapProperties = (properties) => {
  let res = {}
  for (let key in properties) {
    let value = properties[key]
    if (Array.isArray(value)) {
      res[key] = value.join(' ')
    } else {
      res[key] = value
    }
  }
  return res
}

const AstElement = ({ node }) => {
  if (node.type === 'text') {
    return node.value;
  } else if (node.type !== 'element') {
    return null;
  } 

  return React.createElement(
    node.tagName,
    mapProperties(node.properties),
    node.children.map((child, index) => <AstElement key={index} node={child}/>)
  )
}

const Summary = ({
  edge: {
    node: {
      frontmatter: {
        title,
        date,
        iso8601date,
        path,
      },
      htmlAst: {
        children
      }
    }
  }
}) => {
  const elements = children.slice(0, SUMMARY_ELEMENTS)
                           .map((child, index) => <AstElement key={index} node={child}/>)
  return (
    <div>
      <Headline title={title} date={date} path={path}
                iso8601date={iso8601date}/>
      { elements }
      <Link to={path}>Read More</Link>
    </div>
  )
}

const Page = ({
  allMarkdownRemark: {
    edges
  }
}) => {
  return (
    <Layout className={styles.articleSummaries}>
      <SEO title="Home" keywords={[]} />
      { edges.map(edge => (
          <Summary key={edge.node.frontmatter.path}
                  edge={edge}/>
        ))
      }
    </Layout>
  )
}

export default () => <StaticQuery query={QUERY} render={Page}/>
